import { Building } from '@styled-icons/remix-line';
import { Building as BuildingFill } from '@styled-icons/remix-fill';
import { Icon, IconProps } from './Icon';

export function OfficeBuildingIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Building />
    </Icon>
  );
}

export function OfficeBuildingFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <BuildingFill />
    </Icon>
  );
}
